<template>
  <div>
    <v-tabs fixed-tabs background-color="indigo" dark>
      <v-tab> {{ user.firstname }} {{ user.lastname }} </v-tab>
      <v-tab v-if="permitted('SuperAdmin')">
        {{ $t("auditLog.title") }}
      </v-tab>

      <v-tab-item :key="0">
        <v-form @submit.prevent="handleSubmit" v-model="valid" ref="form">
          <v-card :loading="status.loading">
            <v-card-title>
              <span class="title">{{ $t("user.userInfo") }}</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-text-field
                :label="$t('user.fields.firstName')"
                name="firstname"
                prepend-icon="mdi-account-box-outline"
                type="text"
                v-model="user.firstname"
                :rules="stringRules"
              ></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field
                :label="$t('user.fields.lastName')"
                name="lastname"
                prepend-icon="mdi-account-box-outline"
                type="text"
                v-model="user.lastname"
                :rules="stringRules"
              ></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field
                :label="$t('user.fields.email')"
                name="email"
                prepend-icon="mdi-at"
                type="text"
                v-model="user.email"
                :rules="stringRules"
              ></v-text-field>
            </v-card-text>

            <v-card-text>
              <phone-number-input v-model="user.phonenumber" />
            </v-card-text>

            <v-card-text>
              <v-text-field
                :label="$t('user.fields.newPassword')"
                name="password"
                prepend-icon="mdi-textbox-password"
                type="password"
                v-model="user.password"
                autocomplete="new-password"
              ></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-text-field
                :label="$t('user.fields.passwordConfirm')"
                name="passwordConfirm"
                prepend-icon="mdi-lock-check-outline"
                type="password"
                v-model="passwordConfirm"
                :rules="passwordRules"
              ></v-text-field>
            </v-card-text>

            <v-card-text
              v-if="isSuperAdmin() && canAccessFeature(FEATURES.MULTI_COMPANY)"
            >
              <external-user-selector v-model="user.userId" />
            </v-card-text>

            <v-card-actions>
              <v-row>
                <v-col cols="12" sm="8" md="6" align="start">
                  <v-btn color="primary" type="submit" large>{{
                    $t("common.save")
                  }}</v-btn>
                </v-col>
                <v-spacer> </v-spacer>

                <v-col cols="12" sm="8" md="6" align="end">
                  <v-btn
                    color="error"
                    :disabled="!valid || user.userId === currentUser.userId"
                    text
                    @click="confirmDelete = true"
                    v-if="permitted('User.Delete')"
                    >{{ $t("common.delete") }}</v-btn
                  >

                  <v-dialog
                    v-model="confirmDelete"
                    max-width="90%"
                    v-if="permitted('User.Delete')"
                  >
                    <v-card>
                      <v-card-title class="headline">{{
                        $t("common.verify")
                      }}</v-card-title>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          color="primary lighten-1"
                          text
                          @click="confirmDelete = false"
                        >
                          {{ $t("common.disagree") }}
                        </v-btn>

                        <v-btn color="primary" text @click="submitDelete">
                          {{ $t("common.accept") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-tab-item>
      <v-tab-item :key="1">
        <audit-log-list :is-user="true" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ExternalUserSelector from "../company/ExternalUserSelector.vue";
import AuditLogList from "../auditLog/AuditLogList.vue";

export default {
  name: "UserDetail",

  components: { ExternalUserSelector, AuditLogList },

  data() {
    return {
      valid: false,
      image: null,
      confirmDelete: false,
      passwordConfirm: "",

      user: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        password: "",
      },

      stringRules: [
        (v) => !!v || this.$t("validation.requiredSingle"),
        (v) => (v && v.length <= 255) || this.$t("validation.toLong"),
      ],

      phoneNumberRules: [
        (v) => !!v || this.$t("validation.requiredSingle"),
        (v) =>
          (v !== undefined && v[0] !== "0") ||
          this.$t("validation.contryCodeError"),
      ],

      userInput: {},
    };
  },

  computed: {
    ...mapState("users", {
      currentUser: "currentUser",
      status: "status",
      sUser: "user",
    }),

    passwordRules() {
      return [
        (v) =>
          this.user.password == "" ||
          this.user.password == null ||
          v == this.user.password ||
          this.$t("validation.passwordMissmatch"),
      ];
    },
  },

  methods: {
    ...mapActions("users", ["editUser", "getById", "delete"]),

    async handleSubmit() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }

      var payload = this.user;

      await this.editUser(payload);
      this.$router.push("/company");
    },

    async submitDelete() {
      let id = this.$route.params.id;

      if (this.permitted("User.Delete")) {
        await this.delete(id);
        this.$router.push("/company");
      }
    },

    updateUserInformation(user) {
      this.userInput = user;
    },
  },

  mounted() {
    if (
      this.currentUser.userId != this.$route.params.id &&
      !this.permitted("User.Update")
    ) {
      this.$router.push("/company");
    }

    // This user is being fetched in the router
    if (this.sUser) {
      this.user = this.sUser;
    }
  },
};
</script>

<style></style>
